import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const Extension = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="relative !bg-transparent overflow-hidden after:absolute after:inset-0 after:-z-1 after:bg-[url('/images/roadmap/voting-bg.webp')] after:bg-right after:mix-blend-multiply after:bg-white after:sm:bg-cover">
      <div className="xl:flex xl:space-x-2">
        <div className="xl:w-1/2">
          <div className="sm:h-[220px] xl:h-full xl:min-h-[480px]"></div>
        </div>
        <div className="xl:w-1/2">
          <CardText
            title='Voting mechanics'
            text="SafeSoul gives a community power to report scams and suspicious content through the decentralized voting system. The result of their votes is reflected in the security status change. There are three roles with different voting power and sequential check: Scout, Patrol, OG Patrol. This multi-step procedure keeps the system transparent, decentralized and balanced with speed and efficiency. "
            size='L'
            id="voting"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default Extension;