import React from 'react';
import classnames from 'classnames';

const Title = ({ children, locked = false, ...props }) => {
  return (
    <div {...props}>
      <div className="mb-5">
        <span className={classnames(locked ? 'text-[#B3B3B3]' : 'text-yellow', 'flex items-center')}>
          <span className="block bg-current rounded-full h-4 w-4"></span> {locked ?
            <span className='ml-2 leading-none font-bold text-[#B3B3B3]'>Pending</span> :
            <span className='ml-2 leading-none font-bold text-[#B3B3B3]'>Done</span>
          }
        </span>
      </div>
      {children}
    </div >
  );
};

export default Title;