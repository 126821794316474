import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const GoogleSearch = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="overflow-hidden">
      <div className="sm:flex sm:space-x-2">
        <div className="sm:w-1/2">
          <CardText
            title='Scan with ML'
            text="Leveraging cutting-edge AI techniques, our innovative browser extension ensures unparalleled security by effectively detecting fake and malicious domains through visual similarity analysis, homoglyph recognition, and natural language processing. Experience confidence and safety in your web surfing with our revolutionary solution for safeguarding your digital life."
            size='M'
            locked={true}
            id="scan-ml"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
        <div className="relative sm:w-1/2">
          <div className="p-4">
            <div className="rounded-lg overflow-hidden">
              <picture>
                <source srcSet="/images/roadmap/scan-ml.webp" type="image/webp" />
                <source srcSet="/images/roadmap/scan-ml.jpg" type="image/jpeg" />
                <img src="/images/roadmap/scan-ml.jpg" className="bg-gray-500 w-full object-cover aspect-[4/3]" alt="Scam ML" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default GoogleSearch;