import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const TokenSale = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="!bg-[#292929] overflow-hidden">
      <div className="md:flex md:space-x-2 text-white">

        <div className="w-full md:w-1/2 relative z-1">
          <CardText
            title='20 000 Token sale I'
            text="The open sale takes 76 hours or less in case of the sold-out, with a 10 NFT per wallet limit. Everyone who mints on open sale will get a special NFT attribute that could be integrated in the Digital Soul."
            locked={true}
            size='M'
            id="token-sale"
            likes={likes}
            isLoaded={isLoaded}
            dark={true}
          />
        </div>

        <div className="md:w-1/2">
          <div className="-mt-10 md:mt-0 md:-ml-16 h-auto md:h-[480px]">
            <picture>
              <source srcSet="/images/roadmap/token-sale.webp" type="image/webp" />
              <source srcSet="/images/roadmap/token-sale.jpg" type="image/jpeg" />
              <img src="/images/roadmap/token-sale.jpg" alt="" className='h-auto md:h-full object-contain object-center' />
            </picture>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default TokenSale;