import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const Allowlist = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="overflow-hidden">
      <div className="md:flex md:space-x-2">
        <div className="w-full md:w-1/2 relative z-1">
          <CardText
            title='Allowlist'
            text="The allowlist system is designed to prioritize DA holders, partners, investors and community members in the token sale flow. Before the open sale starts there are two allowlist phases with a privileged token per wallet limit for community members only."
            size='M'
            locked={true}
            id="allowlist"
            likes={likes}
            isLoaded={isLoaded}
            dark={true}
          />
        </div>
        <div className="relative md:w-1/2 h-[200px] md:min-h-[480px]">
          <picture>
            <source srcSet="/images/roadmap/allowlist.webp" type="image/webp" />
            <source srcSet="/images/roadmap/allowlist.png" type="image/png" />
            <img src="/images/roadmap/allowlist.png" alt="" className='md:absolute md:right-0 md:top-0 h-full object-contain' />
          </picture>
        </div>
      </div>
    </CardWrapper>
  );
};

export default Allowlist;