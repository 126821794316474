import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const Features = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="overflow-hidden">
      <div className="sm:flex sm:space-x-2">
        <div className="sm:w-1/2">
          <div className="h-[240px] sm:h-full sm:min-h-[480px]">
            <div className="relative h-full">
              <picture>
                <source srcSet="/images/roadmap/features.webp" type="image/webp" />
                <source srcSet="/images/roadmap/features.png" type="image/png" />
                <img src="/images/roadmap/features.png" alt="Extension features" className="absolute top-0 left-0 w-full h-full object-cover mx-auto" />
              </picture>
            </div>
          </div>
        </div>
        <div className="sm:w-1/2">
          <CardText
            title='Extension features'
            text="SafeSoul highlights security statuses all across the web, parses information about the page, connects social media links, supports crypto wallets, activates tokens, sends Patrol alerts, shares invites for OG role, shows achievements and many more."
            size='M'
            id="features"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default Features;