import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const Marketplace = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="!bg-black overflow-hidden">
      <div className="flex flex-col sm:flex-row sm:space-x-2 text-white">
        <div className="sm:w-1/2 relative z-1">
          <CardText
            title='Various marketplaces'
            text="Safesoul collects all the official data on every project and shows links to marketplaces right in the extension. The extension helps the user to be sure that links are safe and reduces the risk of getting to a scam site, as the user switches between links to different marketplaces inside the extension"
            size='M'
            id="marketplace"
            likes={likes}
            isLoaded={isLoaded}
            dark={true}
          />
        </div>
        <div className="order-first sm:order-none sm:w-1/2">
          <div className="relative h-[200px] sm:h-full sm:min-h-[480px]">
            <picture>
              <source srcSet="/images/roadmap/marketplace.webp" type="image/webp" />
              <source srcSet="/images/roadmap/marketplace.png" type="image/png" />
              <img src="/images/roadmap/marketplace.png" alt="" className='absolute right-0 top-0 h-full max-w-none' />
            </picture>
          </div>

        </div>
      </div>
    </CardWrapper>
  );
};

export default Marketplace;