import React, { useCallback, useEffect, useState, useMemo } from 'react';
import client from '@/helpers/apiClient';
import classnames from 'classnames';

function Like({ likes = 0, id, dark = false }) {
  const [isLiked, setLiked] = useState(false);
  const [count, setCount] = useState(likes);

  useEffect(() => {
    const ids = JSON.parse(window.localStorage.getItem('roadmapLikes')) || [];
    // setLiked(true);

    if (ids.includes(id)) {
      setLiked(true);
    }
  }, []);

  useEffect(() => {
    setCount(likes);
  }, [likes]);

  const voteHandler = useCallback(async () => {
    const ids = JSON.parse(window.localStorage.getItem('roadmapLikes')) || [];

    if (!ids.includes(id)) {
      setLiked(true);
      setCount(state => state + 1);

      ids.push(id);
      window.localStorage.setItem('roadmapLikes', JSON.stringify(ids));

      await client.post(`/roadmaps/${id}`, {
        like: '+1',
      });

    } else {
      setLiked(false);
      setCount(state => state - 1);

      ids.splice(ids.indexOf(id), 1);
      window.localStorage.setItem('roadmapLikes', JSON.stringify(ids));

      await client.post(`/roadmaps/${id}`, {
        like: '-1',
      });


    }
  }, [isLiked]);

  const colorSvg = useMemo(() => {
    return dark ? {
      fill: isLiked ? '#c2e900' : 'none',
      stroke: isLiked ? '#c2e900' : 'white',
      text: 'text-white/40'
    } : {
      fill: isLiked ? '#c2e900' : 'none',
      stroke: isLiked ? '#c2e900' : 'black',
      text: 'text-black/40'
    };
  }, [isLiked]);


  return (
    <div className='flex items-center'>
      <button
        type="button" onClick={voteHandler}
        className='group'
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='group-hover:scale-110 transition'>
          <path fillRule="evenodd" clipRule="evenodd" d="M15.696 4C18.871 4 21 6.98 21 9.755C21 15.388 12.161 20 12 20C11.839 20 3 15.388 3 9.755C3 6.98 5.129 4 8.304 4C10.119 4 11.311 4.905 12 5.711C12.689 4.905 13.881 4 15.696 4Z" stroke={colorSvg.stroke} fill={colorSvg.fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>

      {
        count === 0 ?
          null :
          <span className={classnames('ml-2 font-bold leading-none', colorSvg.text)}>{count}</span>
      }
    </div>
  );
}

export default Like;