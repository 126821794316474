import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const GoogleSearch = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="overflow-hidden">
      <div className="sm:flex sm:space-x-2">
        <div className="sm:w-1/2">
          <div className="h-[280px] sm:h-[480px]">
            <picture>
              <source srcSet="/images/roadmap/google-search.webp" type="image/webp" />
              <source srcSet="/images/roadmap/google-search.jpg" type="image/jpeg" />
              <img src="/images/roadmap/google-search.jpg" alt="Google search engine mark" className="h-full object-cover mx-auto" />
            </picture>
          </div>
        </div>
        <div className="sm:w-1/2">
          <CardText
            title='Google search engine mark'
            text="The security status is highlighted all over the web, as well as right in Google search. Google ads, search results and any scam links on the Internet will be highlighted wherever the scammers hide them."
            size='M'
            id="google-search"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default GoogleSearch;