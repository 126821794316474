import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const SpeakArt = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="!bg-black overflow-hidden">
      <div className="flex flex-col-reverse md:flex-row md:space-x-2 text-white">
        <div className="w-full md:w-1/2 relative z-1">
          <CardText
            title='Speak through art'
            text="Souls ecosystem has a deep background in art and is deeply interconnected with the artists' community in web3. Artists are one of the most vulnerable groups within the community, and scammers use perverse deception schemes to steal artists' accounts and wallets. We want to highlight this problem through art by collaborating with artists, galleries and hosting art events."
            locked={true}
            size='M'
            id="speak-art"
            dark={true}
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
        <div className="relative md:w-1/2">
          <div className="">
            <div className="xl:pr-16">
              <picture>
                <source srcSet="/images/roadmap/speak-art.webp" type="image/webp" />
                <source srcSet="/images/roadmap/speak-art.jpg" type="image/jpeg" />
                <img src="/images/roadmap/speak-art.jpg" className="w-full object-contain object-center xl:object-cover h-[280px] md:h-full md:min-h-[480px]" alt="Speak art" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default SpeakArt;