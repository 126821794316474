import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const Solution = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="!bg-[#E9E9E9] overflow-hidden">
      <div className="sm:flex sm:space-x-2">
        <div className="sm:w-1/2 sm:pr-8">
          <CardText
            title='Solution'
            text="A cyber security tool that combines the best practices of automatic solutions and human experience and knowledge and accompanies the user all across the web sounds as what we were searching for."
            size='L'
            id="solution"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
        <div className="sm:w-1/2">
          <div className="h-[270px] sm:h-full sm:min-h-[480px]">
            <picture>
              <source srcSet="/images/roadmap/solution.webp" type="image/webp" />
              <source srcSet="/images/roadmap/solution.png" type="image/png" />
              <img src="/images/roadmap/solution.png" alt="" className='h-full w-full object-cover' />
            </picture>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default Solution;