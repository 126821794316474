import React from 'react';

import Card from './common/Card';

const Browsers = ({ isLoaded, likes }) => {
  return (

    <Card
      title="Browsers 1"
      text="Safesoul browser extension is approved in Chrome Web Store and is supported by Chrome and Brave browsers."
      id="browsers-1"
      likes={likes}
      isLoaded={isLoaded}
      additional={
        <>
          <div className="flex space-x-6">
            <div>
              <svg
                width={48}
                height={48}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.927.002s7.048-.316 10.785 6.768H11.327S9.18 6.7 7.344 9.307c-.528 1.094-1.094 2.221-.458 4.442-.916-1.552-4.864-8.425-4.864-8.425S4.807.282 11.927.002Z"
                  fill="#EF3F36"
                />
                <path
                  d="M22.345 17.986s-3.252 6.264-11.252 5.955l5.693-9.86s1.137-1.825-.204-4.718c-.682-1.005-1.377-2.056-3.618-2.616 1.803-.016 9.727 0 9.727 0s2.969 4.933-.346 11.24Z"
                  fill="#FCD900"
                />
                <path
                  d="M1.557 18.036s-3.8-5.948.471-12.722l5.69 9.858s1.016 1.898 4.189 2.182c1.21-.09 2.468-.165 4.076-1.822-.887 1.568-4.864 8.422-4.864 8.422s-5.76.105-9.562-5.918Z"
                  fill="#61BC5B"
                />
                <path
                  d="m11.09 24 1.6-6.682s1.76-.139 3.237-1.757C15.01 17.173 11.088 24 11.088 24Z"
                  fill="#5AB055"
                />
                <path
                  d="M6.562 12.075a5.335 5.335 0 1 1 10.67.002 5.335 5.335 0 0 1-10.67-.002Z"
                  fill="#fff"
                />
                <path
                  d="M7.456 12.075a4.442 4.442 0 1 1 8.883 0 4.442 4.442 0 0 1-8.883 0Z"
                  fill="url(#a-1)"
                />
                <path
                  d="m22.689 6.75-6.594 1.935s-.995-1.46-3.133-1.935c1.855-.01 9.727 0 9.727 0Z"
                  fill="#EACA05"
                />
                <path
                  d="M6.757 13.505c-.926-1.604-4.735-8.181-4.735-8.181l4.883 4.83s-.5 1.032-.313 2.508l.165.843Z"
                  fill="#DF3A32"
                />
                <defs>
                  <linearGradient
                    id="a-1"
                    x1={11.897}
                    y1={7.696}
                    x2={11.897}
                    y2={16.25}
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#86BBE5" />
                    <stop offset={1} stopColor="#1072BA" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div>
              <svg
                width={48}
                height={48}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m19.873 5.75.564-1.383s-.71-.764-1.583-1.638c-.873-.873-2.71-.364-2.71-.364L14.05 0H6.68L4.59 2.384s-1.838-.51-2.711.364A64.323 64.323 0 0 0 .295 4.385l.564 1.383-.71 2.038s2.11 7.97 2.347 8.952c.492 1.91.82 2.657 2.202 3.64 1.383.982 3.876 2.656 4.294 2.91.4.255.928.692 1.383.692.455 0 .964-.437 1.383-.691.4-.255 2.911-1.947 4.294-2.912 1.383-.964 1.71-1.71 2.202-3.639.255-.982 2.347-8.952 2.347-8.952l-.728-2.056Z"
                  fill="url(#a-4)"
                />
                <path
                  d="M15.56 3.894s2.694 3.257 2.694 3.967-.346.873-.692 1.237c-.345.364-1.838 1.947-2.02 2.147-.2.2-.582.51-.363 1.073.236.546.582 1.256.2 1.984-.382.71-1.037 1.2-1.456 1.11-.418-.073-1.42-.6-1.783-.837-.364-.237-1.51-1.183-1.51-1.547 0-.364 1.2-1.019 1.419-1.164.218-.146 1.22-.728 1.237-.947.019-.218.019-.29-.29-.855-.292-.564-.838-1.31-.747-1.801.091-.491.946-.746 1.565-.983.619-.236 1.801-.673 1.947-.746.146-.073.11-.127-.346-.182-.455-.036-1.71-.218-2.292-.054-.583.163-1.547.4-1.638.527-.073.128-.146.128-.073.583.073.436.51 2.583.546 2.965.036.383.127.637-.291.728-.4.091-1.092.255-1.328.255-.237 0-.928-.164-1.329-.255-.4-.09-.327-.345-.29-.728.036-.382.454-2.51.545-2.965.073-.437.018-.455-.073-.583-.072-.127-1.055-.364-1.637-.527-.583-.164-1.838 0-2.293.054-.455.037-.491.11-.346.182.146.073 1.329.51 1.947.746.619.237 1.474.492 1.565.983.091.491-.437 1.237-.746 1.801-.291.564-.31.619-.291.855.018.219 1.019.801 1.237.947.219.145 1.42.8 1.42 1.164 0 .364-1.147 1.31-1.51 1.547-.365.236-1.347.746-1.784.837-.418.072-1.073-.4-1.456-1.11-.382-.71-.036-1.42.2-1.984.237-.545-.163-.855-.363-1.073-.146-.218-1.62-1.801-1.965-2.147-.346-.364-.674-.546-.674-1.237 0-.692 2.693-3.967 2.693-3.967s2.275.437 2.584.437c.31 0 .964-.255 1.583-.455s1.019-.2 1.019-.2.4 0 1.019.2c.619.2 1.274.455 1.583.455.31 0 2.584-.437 2.584-.437Z"
                  fill="#fff"
                />
                <path
                  d="M13.541 16.358c.164.109.073.31-.09.4-.146.11-2.203 1.692-2.385 1.874-.2.182-.49.455-.691.455-.2 0-.491-.29-.691-.455-.2-.182-2.238-1.765-2.384-1.874-.146-.109-.255-.31-.091-.4.164-.11.691-.364 1.401-.746.728-.382 1.62-.692 1.765-.692.146 0 1.037.31 1.765.692.71.382 1.22.655 1.401.746Z"
                  fill="#fff"
                />
                <path
                  d="M16.143 2.384 14.05 0H6.681L4.59 2.384s-1.838-.51-2.711.364c0 0 2.456-.219 3.293 1.146 0 0 2.275.437 2.584.437.31 0 .964-.255 1.583-.455s1.019-.2 1.019-.2.4 0 1.019.2c.618.2 1.274.455 1.583.455.31 0 2.584-.437 2.584-.437.837-1.365 3.293-1.146 3.293-1.146-.855-.874-2.693-.364-2.693-.364"
                  fill="url(#b-4)"
                />
                <defs>
                  <linearGradient
                    id="a-4"
                    x1={0.149}
                    y1={11.995}
                    x2={20.585}
                    y2={11.995}
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F50" />
                    <stop offset={0.41} stopColor="#F50" />
                    <stop offset={0.582} stopColor="#FF2000" />
                    <stop offset={1} stopColor="#FF2000" />
                  </linearGradient>
                  <linearGradient
                    id="b-4"
                    x1={2.249}
                    y1={2.161}
                    x2={18.848}
                    y2={2.161}
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF452A" />
                    <stop offset={1} stopColor="#FF2000" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </>
      }
    />
  );
};

export default Browsers;