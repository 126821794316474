import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const AdvertisingPlacement = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="overflow-hidden">
      <div className="sm:flex sm:space-x-2">
        <div className="sm:w-1/2">
          <div className="h-auto sm:h-[480px]">
            <picture>
              <source srcSet="/images/roadmap/placement.webp" type="image/webp" />
              <source srcSet="/images/roadmap/placement.jpg" type="image/jpeg" />
              <img src="/images/roadmap/placement.jpg" alt="" className="h-auto md:h-full object-cover" />
            </picture>
          </div>
        </div>
        <div className="sm:w-1/2">
          <CardText
            title='Advertising placement'
            text="Development and design of ad sales and ad marketplace. SafeSoul sells ad space, and the revenue generated from ads is distributed among token holders."
            locked={true}
            size='M'
            id="advertising-placement"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default AdvertisingPlacement;