import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const TwitterScamTracker = ({ isLoaded, likes }) => {
  return (
    <CardWrapper>
      <div className="sm:flex sm:space-x-2">
        <div className="sm:w-1/2">
          <div className="relative h-[280px] sm:h-full sm:min-h-[480px]">
            <picture>
              <source srcSet="/images/roadmap/twitter-scam-tracker.webp" type="image/webp" />
              <source srcSet="/images/roadmap/twitter-scam-tracker.png" type="image/png" />
              <img src="/images/roadmap/twitter-scam-tracker.png" alt="" className='absolute bottom-0 w-full sm:w-auto sm:left-1/2 sm:-translate-x-1/2 sm:h-[520px] max-h-full object-cover sm:object-contain object-top sm:object-center' />
            </picture>
          </div>
        </div>
        <div className="sm:w-1/2">
          <CardText
            title='Twitter DM
              scam-tracker'
            text="Security status is shown in dms on Twitter as well, highlighting messages and requests from scammers and preventing users from interacting and opening potentially dangerous dms."
            size='M'
            id="twitter-scam-tracker"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default TwitterScamTracker;