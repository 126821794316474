import React from 'react';
import CardWrapper from './CardWrapper';
import CardText from './CardText';

const Card = ({ id, likes = [], isLoaded = false, title, text, additional = null, locked = false }) => {
  return (
    <CardWrapper>
      <>
        <CardText
          title={title}
          text={text}
          additional={additional}
          locked={locked}
          id={id}
          likes={likes}
          isLoaded={isLoaded}
        />
      </>
    </CardWrapper>
  );
};

export default Card;