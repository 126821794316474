import React from 'react';
import classnames from 'classnames';

const CardList = ({ cards, className = 'sm:w-1/2' }) => {
  return (
    <div className="sm:flex sm:flex-wrap -m-1">
      {cards.map((item, index) =>
        <div key={index} className={classnames(className, "p-1")}>{item}</div>
      )}
    </div>
  );
};

export default CardList;