import React, { useEffect, useCallback, useState } from 'react';
import client from '@/helpers/apiClient';

import HeadSection from '@/components/common/Head';

import Header from '@/components/Header';
import Footer from '@/components/Footer';

import Card from '@/sections/roadmap/common/Card';
import CardList from '@/sections/roadmap/common/CardList';
import MainCard from '@/sections/roadmap/common/MainCard';

import Solution from '@/sections/roadmap/Solution';
import Extension from '@/sections/roadmap/Extension';
import Voting from '@/sections/roadmap/Voting';
import Design from '@/sections/roadmap/Design';
import Features from '@/sections/roadmap/Features';
import Achivements from '@/sections/roadmap/Achivments';
import Marketplace from '@/sections/roadmap/Marketplace';
import Browsers_1 from '@/sections/roadmap/Browsers_1';
import Browsers_2 from '@/sections/roadmap/Browsers_2';
import GoogleSearch from '@/sections/roadmap/GoogleSearch';
import ScanML from '@/sections/roadmap/ScanML';
import TwitterScamTracker from '@/sections/roadmap/TwitterScamTracker';
import SpeakArt from '@/sections/roadmap/SpeakArt';
import StatusYoutube from '@/sections/roadmap/StatusYoutube';
import Allowlist from '@/sections/roadmap/Allowlist';
import SocialParsing from '@/sections/roadmap/SocialParsing';
import TokenSale from '@/sections/roadmap/TokenSale';
import RevokeAccess from '@/sections/roadmap/RevokeAccess';
import TransactionSimulation from '@/sections/roadmap/TransactionSimulation';
import AdvertisingPlacement from '@/sections/roadmap/AdvertisingPlacement';

const RoadmapPage = () => {
  const [likes, setLikes] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  const getLikes = useCallback(async () => {
    const { data } = await client.get('/roadmaps');

    setLikes(data);

    setLoaded(true);
  }, []);

  useEffect(() => {
    getLikes();
  }, []);

  return (
    <div>
      <Header />

      <main>
        <div className="pt-40 lg:pt-52 pb-20 lg:pb-80">
          <div className="fluid-container">
            <h1 className='text-6xl font-bold'>Roadmap</h1>
          </div>

          <section className="pt-10">
            <div className="fluid-container">
              <div className="space-y-5 sm:space-y-10 md:space-y-20">

                <div>
                  <CardList
                    cards={[
                      <Card
                        title="Idea"
                        text="The Web3 community faces scams on a daily basis, this problem is growing over time, and there is no way to erase it from the space at once. Endless stories about hacked account and stolen wallets are ruining NFT's reputation and hindering mass adoption. The need for cybersecurity tools is high."
                        id="idea"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Research"
                        text="Scam technologies and methods are evolving and becoming more complex day by day. This makes it impossible to automate and standardize security. It became obvious that only a balanced system of technology and human expertise could guarantee reliability and effectiveness."
                        id="research"
                        likes={likes}
                        isLoaded={isLoaded}
                      />
                    ]}
                  />
                </div>

                <div>
                  <Solution
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <Extension
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <CardList
                    cards={[
                      <Card
                        title="Smart-contract"
                        text="Blockchain solution allows to build a responsible voting system and tokenomics, for this, a smart contract is being developed."
                        id="smart-contract"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Database"
                        text="SafeSoul database is constantly updated and learning, it uses AI to analyze webpages and track activity.  Currently it contains 20,000 scam pages and 5,000 verified by OS."
                        id="database"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Parsing data OpenSea"
                        text="The extension is parsing all the official data about the project, market statistics, social media links etc from the OpenSea and shows OpenSea verified status if collection has an official badge."
                        id="parsing-data-opensea"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Web site"
                        text="Creation of a website to share the mission of SafeSoul and explain the product by showing  the logic behind it and describing the functionality of the extension."
                        id="web-site"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Team assembling"
                        text="A team of developers, designers, marketing and management specialists is formed and keeps growing. Currently SafeSoul has 22 people on board."
                        id="team-assembling"
                        likes={likes}
                        isLoaded={isLoaded}
                      />
                    ]}
                  />
                </div>

                <div>
                  <Voting
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <Design
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <div>
                    <Features
                      likes={likes}
                      isLoaded={isLoaded}
                    />
                  </div>

                  <div className='py-2'>
                    <CardList
                      cards={[
                        <Card
                          title="Twitter API"
                          text="SafeSoul connects to Twitter, to show users pfp and  allow to collect achievements"
                          id="twitter-api"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Support Wallets"
                          text="SafeSoul supports MetaMask, Coinbase Wallet, Trust Wallet, Ledger"
                          id="support-wallets"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Activate token"
                          text="Bound token instructions and activation is executed inside the extension’ interface."
                          id="activate-token"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="OG Invites"
                          text="OG patrol role is invites only, OG see their invite number and share it with trusted experts through extension"
                          id="og-invites"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Discord connection"
                          text="SafeSoul connects to discord to share Patrol instructions and alerts"
                          id="discord-connection"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Achievements board"
                          text="Patrol achievements are collected and shown next to users' pfp on Twitter and in the extension"
                          id="achievements-board"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Dynamic icon"
                          text="A live color changing logo is visible in the extension bar and reflects the current security status of the opened page"
                          id="dynamic-icon"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Full-screen alert"
                          text="In case of entering a scammed page, the user will see a full-screen scam alert, before seeing the content."
                          id="full-screen-alert"
                          likes={likes}
                          isLoaded={isLoaded}
                        />
                      ]}
                      className="sm:w-1/2 lg:w-1/3 xl:w-1/4"
                    />
                  </div>

                  <div>
                    <Achivements
                      likes={likes}
                      isLoaded={isLoaded}
                    />
                  </div>

                  <div className='pt-2'>
                    <CardList
                      cards={[
                        <Card
                          title="TestNet"
                          text="For community and team testing of the extension performance and voting procedure, all processes connected to minting and token activation are held on Goerli test network."
                          id="features-testnet"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Tokenomics"
                          text="The logic of role distribution is built on tokenomics. 100k token sale is scheduled in three phases: 25,000 tokens; 25,000 and 40,000 with the progressive price increases and the number of tokens per wallet limit decreasing accordingly."
                          id="features-tokenomics"
                          likes={likes}
                          isLoaded={isLoaded}
                        />
                      ]}
                    />
                  </div>
                </div>

                <div>
                  <CardList
                    cards={[
                      <Card
                        title="Demo I, II, III"
                        text="SafeSoul team runs closed demo sessions to test the functionality of the extension beta and collect feedback from the community. The goal is to test various scenarios of user journey using different devices, browsers and wallets."
                        id="demo-i-ii-iii"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Private presentations"
                        text="There were a series of private pitches and presentations with Web3 experts. SafeSoul team was collecting feedback and thoughts about the extension from extraordinary community members."
                        id="private-presentations"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Community offer"
                        text="Safesoul has an exclusive offer for partners and blue chip communities, providing them with a special role for their moderators to mark affiliated and not affiliated projects. This will help to highlight fake pages and verify side collections fast and efficiently."
                        id="community-offer"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Browsers_1
                        likes={likes}
                        isLoaded={isLoaded}
                      />
                    ]}
                  />
                </div>

                <div>
                  <Marketplace
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <GoogleSearch
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <MainCard
                    locked={true}
                    here={true}
                    title="Early access"
                    className="bg-[url(/images/roadmap/beta-release-bg.webp)] bg-[-160px_center] sm:bg-center lg:bg-[160px_center]"
                  />
                </div>

                <div>
                  <ScanML
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <div>
                    <CardList
                      cards={[
                        <Card
                          title="MainNet eth deploy"
                          text="The development team finalizes the smart contract and after final testing deploys of smart contract on the Ethereum Mainnet. "
                          locked={true}
                          id="mainnet-eth-deploy"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Communities onboarding"
                          text="SafeSoul shares community offers, connects to blue chip communities, builds partnerships with DAOs and trusted groups, assigns special community moderators roles to build a community of experts and change the security culture in the space."
                          id="communities-onboarding"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Complex webpages parsing"
                          text="SafeSoul is parsing information precisely about the project from the marketplace websites and other web pages that have sub-pages."
                          id="complex-webpages-parsing"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Web3 experts database"
                          text="Communicating with Web3 experts, collecting feedback and best practice about cybersecurity and community trends."
                          id="web3-experts-database"
                          likes={likes}
                          isLoaded={isLoaded}
                        />
                      ]}
                    />
                  </div>
                  <div className='pt-2'>
                    <CardList
                      className="sm:w-1/2 lg:w-1/3 xl:w-1/4"
                      cards={[
                        <Card
                          title="Auto whiteList community"
                          text="WL for extension access is shared among holders,  communities and partners automatically."
                          id="auto-whitelist-community"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Discord bot-channels"
                          text="Automatic security alerts and news on discord."
                          id="twitter-parsing"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Twitter parsing"
                          text="Engage, Achieve and Stand Out. With Twitter parsing function, users have the power to unlock exclusive achievements when tag SafeSoul on Twitter and showcase them proudly next to their profile picture."
                          locked={true}
                          id="discord-bot-channels"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                        <Card
                          title="Activation. Bound token release"
                          text="Bound token activation is available in the extension. Activation evokes the burning of the SafeSoul token and sending a nontransferable token to the Patrol member wallet."
                          locked={true}
                          id="activation-bound-token-release"
                          likes={likes}
                          isLoaded={isLoaded}
                        />,
                      ]}
                    />
                  </div>

                </div>

                <div>
                  <TwitterScamTracker
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <CardList
                    cards={[
                      <Card
                        title="Academy"
                        text="SafeSoul runs education sessions and workshops, teaching cybersecurity to the community and sharing best practices. We emphasize that extension is a tool and actions on web3 should be responsible."
                        locked={true}
                        id="education-programm"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Invites sharing"
                        text="OG patrol role is invited only, OG sees their invite number and shares it with trusted experts in the space. Thus SafeSoul builds a closed network of experts responsible for the final decision on the security status."
                        locked={true}
                        id="invites-sharing"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                    ]}
                  />
                </div>

                <div>
                  <CardList
                    cards={[
                      <Card
                        title="NFT achievements distribution"
                        text="Achievements are unique visuals and NFTs, that are given to Patrol members for extraordinary performance in making Web3 safe. This is a true reputational mark that never is bought or transferred and is forever linked to the proud holder on the blockchain."
                        locked={true}
                        id="nft-achievements-distribution"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Auditing"
                        text="Smart contract auditing and testing are necessary steps to ensure the security and stability of the extension."
                        locked={true}
                        id="auditing"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                    ]}
                  />
                </div>

                <div>
                  <MainCard
                    title="Release"
                    className="bg-[url(/images/roadmap/release-bg.webp)] bg-[12%_center] sm:bg-[] lg:bg-[125px_center]"
                  />
                </div>

                <div>
                  <SpeakArt
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <CardList
                    cards={[
                      <Card
                        title="Whitelist distribution"
                        text="Collecting addresses for whitelists. DA holders, communities and partners of the Souls ecosystem are whitelisted automatically."
                        locked={true}
                        id="whitelist-distribution"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Browsers_2
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Verification applications"
                        text="Collection of applications for SafeSoul verification.New projects and pages need additional evidence of reliability in the first stage.To get a verified SafeSoul badge, a new project can apply for verification, fill out a form, share the required data, and pay a fee."
                        locked={true}
                        id="verification-applications"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Project launch support"
                        text="Projects and startups can apply for SafeSoul support for their launch, token sale, nft mint or any other vulnerable activity. SafeSoul provides extra protection against scammers and fakes specifically on launch dates."
                        locked={true}
                        id="project-launch-support"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                    ]}
                  />
                </div>

                <div>
                  <CardList
                    cards={[
                      <Card
                        title="Hide spam"
                        text="The extension can erase all spam from Twitter by hiding all spam posts. This function has a toggle switch inside the extension."
                        locked={true}
                        id="hide-spam"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Light mode"
                        text="In addition to dark mode, the light mode is added to the extension, giving users a more convenient visual experience. Light and Dark modes can be chosen according to the browser settings."
                        locked={true}
                        id="light-mode"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Chinese localization"
                        text="Our mission is to help make the Internet safe for everyone, so localization of the extension and adding new languages is very important. New languages TBA"
                        locked={true}
                        id="chinese-localization"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                    ]}
                  />
                </div>

                <div>
                  <StatusYoutube
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <MainCard
                    title="Presale"
                    className="bg-[url(/images/roadmap/presale.webp)] bg-[110px_center] sm:bg-[-20%_center] lg:bg-[240px_center]"
                  />
                </div>

                <div>
                  <Allowlist
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <CardList
                    cards={[
                      <Card
                        title="AirDrop for Lords"
                        text="Lords and Ladies get SafeSoul token airdrop priority, being the first to claim it, according to the number of NFTs in wallets."
                        locked={true}
                        id="airdrop-for-lords"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="1 000 Early supporters Achievements"
                        text="The first 1000 users of the SafeSoul extension will get unique achievements that will be shown next to their pfps on Twitter."
                        locked={true}
                        id="1000-early-supporters-achievements"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                    ]}
                  />
                </div>

                <div>
                  <SocialParsing
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <MainCard
                    title="Sale"
                    dark={true}
                    className="bg-[url(/images/roadmap/sale.webp)] bg-[90px_center] sm:bg-[160px_center] lg:bg-[160px_center]"
                  />
                </div>

                <div>
                  <TokenSale
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <CardList
                    cards={[
                      <Card
                        title="DigitalSouls + SafeSoul"
                        text="Soulbound tokens and SafeSoul. TBA"
                        locked={true}
                        id="digitalsouls-safesoul"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="AirDrop DigitalAnimals owners"
                        text="SafeSoul is a part of the Souls ecosystem so all DA holders get their benefits. There will be an AirDrop of SafeSoul tokens to Digital Animals holders according to the number of NFTs in the wallet."
                        locked={true}
                        id="airdrop-digitalanimals-owners"
                        likes={likes}
                        isLoaded={isLoaded}
                      />
                    ]}
                  />
                </div>

                <div>
                  <RevokeAccess
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <CardList
                    cards={[
                      <Card
                        title="Safari core features release"
                        text="The extension is supported by Chrome, Brave, Opera, and Firefox in addition to that SafeSoul works with Safari with its core features, such as live security status, official links, and dynamic icons are supported by Safari browser."
                        locked={true}
                        id="safari-core-features-release"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Gmail"
                        text="Security statuses are visible in Gmail, highlighting scam alerts right in the inbox, email texts, attachments and etc."
                        locked={true}
                        id="gmail"
                        likes={likes}
                        isLoaded={isLoaded}
                      />,
                      <Card
                        title="Facebook"
                        text="Security statuses are shown in the Facebook feed, messages and notifications, drawing users' attention and protecting them from accidental clicks."
                        locked={true}
                        id="facebook"
                        likes={likes}
                        isLoaded={isLoaded}
                      />
                    ]}
                  />
                </div>

                <div>
                  <TransactionSimulation
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

                <div>
                  <CardList
                    cards={[
                      <Card
                        title="Distribution of revenue from advertising and applications"
                        text="Token holders get income from the paid verification program and advertising revenue."
                        locked={true}
                        id="distribution-of-revenue-from-advertising-and-applications"
                        likes={likes}
                        isLoaded={isLoaded}
                      />
                    ]}
                  />
                </div>

                <div>
                  <AdvertisingPlacement
                    likes={likes}
                    isLoaded={isLoaded}
                  />
                </div>

              </div>

            </div>
          </section>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default RoadmapPage;

export const Head = () => <HeadSection title="Roadmap" />;