import React from 'react';
import classnames from 'classnames';

import CardWrapper from './CardWrapper';

const BetaRelease = ({ here = false, locked = false, title, className, dark = false }) => {
  return (
    <CardWrapper className={classnames("overflow-hidden", dark && '!bg-[#292929]')}>
      <div className={classnames("flex bg-[length:auto_100%] xl:bg-right bg-no-repeat", className)}>
        <div className="lg:flex lg:items-center p-4 sm:px-10 sm:py-12">
          <div className="flex items-center text-4xl sm:text-6xl font-bold mr-4">
            <span className={classnames('block flex-shrink-0 h-4 w-4 sm:w-12 sm:h-12 rounded-full mr-2', locked ? 'bg-yellow' : (dark ? 'bg-[#B3B3B3]' : 'bg-black/30'))}></span>
            <h2 className={classnames('leading-none', dark && 'text-yellow')}>{title}</h2>
          </div>
          {
            here && <span className='ml-6 sm:ml-14 lg:ml-0 text-[22px] sm:text-6xl font-bold opacity-20'>Live</span>
          }
        </div>
      </div>
    </CardWrapper>
  );
};

export default BetaRelease;