import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const SocialParsing = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="overflow-hidden">
      <div className="sm:flex sm:space-x-2">
        <div className="sm:w-1/2">
          <div className="relative h-[280px] sm:h-full sm:min-h-[480px]">
            <picture>
              <source srcSet="/images/roadmap/social-parsing.webp" type="image/webp" />
              <source srcSet="/images/roadmap/social-parsing.jpg" type="image/jpeg" />
              <img src="/images/roadmap/social-parsing.jpg" className="absolute top-0 left-0 sm:left-auto sm:right-0 h-full object-cover sm:max-w-none" alt="Social parsing" />
            </picture>
          </div>
        </div>
        <div className="relative sm:w-1/2 z-1">
          <CardText
            title='Social parsing'
            text="All links that are connected to the official OpenSea account are collected in the extension: social media, marketplaces, websites, etc. A handy board with all links helps the user to safely switch between different project resources and social media and be sure that these links are official."
            locked={true}
            size='M'
            id="social-parsing"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default SocialParsing;