import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const RevokeAccess = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="overflow-hidden">
      <div className="sm:flex sm:space-x-2">
        <div className="sm:w-1/2">
          <div className="h-[290px] sm:h-full sm:min-h-[480px] sm:pt-9">
            <div className="relative h-full">
              <div className='absolute top-0 left-0 h-full w-full'>
                <picture>
                  <source srcSet="/images/roadmap/revoke-access.webp" type="image/webp" />
                  <source srcSet="/images/roadmap/revoke-access.png" type="image/png" />
                  <img src="/images/roadmap/revoke-access.png" alt="Revoke access" className=" object-cover h-full mx-auto" />
                </picture>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:w-1/2">
          <CardText
            title='Revoke access'
            text="The extension contains information about all pages connected to the wallet and gives the user the opportunity to revoke access to avoid uncontrolled transactions."
            locked={true}
            size='M'
            id="revoke-access"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default RevokeAccess;