import React from 'react';
import classnames from 'classnames';

const CardWrapper = ({ className, children, ...props }) => {
  return (
    <div className={classnames("h-full bg-white rounded-2xl", className)} {...props}>
      {children}
    </div>
  );
};

export default CardWrapper;