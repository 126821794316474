import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const Design = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="!bg-[#1E2021] overflow-hidden">
      <div className="lg:flex lg:space-x-2 text-white">
        <div className="lg:flex-1">
          <CardText
            title='Design'
            text="Strong product design provides an easy and convenient user experience. SafeSoul design teamis working on the optimal shape of highlights and security statuses on any webpage too draw users attention to potentially risky content and avoid accidental clicks."
            size='L'
            id="design"
            likes={likes}
            isLoaded={isLoaded}
            dark={true}
          />
        </div>
        <div className="lg:w-[400px] xl:w-[801px]">
          <div className="relative h-[290px] lg:h-full xl:h-full xl:min-h-[480px]">
            <picture>
              <source srcSet="/images/roadmap/design.webp" type="image/webp" />
              <source srcSet="/images/roadmap/design.png" type="image/png" />
              <img src="/images/roadmap/design.png" alt="Design" className='absolute top-0 -right-0.5 w-full h-full object-cover' />
            </picture>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default Design;