import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const StatusYoutube = ({ isLoaded, likes }) => {
  return (
    <CardWrapper className="overflow-hidden">
      <div className="sm:flex sm:space-x-2">
        <div className="sm:w-1/2">
          <div className="relative h-[280px] sm:h-auto 2xl:h-full 2xl:min-h-[480px]">
            <picture>
              <source srcSet="/images/roadmap/youtube-status.webp" type="image/webp" />
              <source srcSet="/images/roadmap/youtube-status.jpg" type="image/jpeg" />
              <img src="/images/roadmap/youtube-status.jpg" className="absolute sm:static 2xl:absolute 2xl:top-0 left-1/2 -translate-x-1/2 sm:left-0 sm:translate-x-0 h-full object-cover 2xl:max-w-none" alt='Youtube status' />
            </picture>
          </div>
        </div>
        <div className="relative sm:w-1/2 z-1">
          <CardText
            title='Security status on Youtube'
            text="Dumb and scam videos are highlighted right on YouTube, drawing users' attention and protecting them from clicking on suspicious links and interacting with scammers."
            locked={true}
            size='M'
            id="youtube-status"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default StatusYoutube;