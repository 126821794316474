import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const TransactionSimulation = ({ isLoaded, likes }) => {
  return (
    <CardWrapper>
      <div className="sm:flex sm:space-x-2">
        <div className="sm:w-1/2 sm:pr-8">
          <CardText
            title='Transaction simulation'
            text="SafeSoul intercepts blockchain transactions and predicts their results before real signature. Messages in the wallet can contain confusing symbols and look suspicious, while SafeSoul decodes the potential transaction and shows which transaction was requested, protecting users from phishing and scam. "
            locked={true}
            size='M'
            id="transaction-simulation"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>
        <div className="sm:w-1/2">
          <div className="relative sm:h-full sm:min-h-[480px]">
            <picture>
              <source srcSet="/images/roadmap/transaction-simulation.webp" type="image/webp" />
              <source srcSet="/images/roadmap/transaction-simulation.png" type="image/png" />
              <img src="/images/roadmap/transaction-simulation.png" alt="" className="sm:absolute sm:right-0 sm:bottom-0 object-cover max-w-none w-full sm:w-auto sm:h-[250px] md:h-[300px] lg:h-[350px] xl:h-[400px] 2xl:h-[500px]" />
            </picture>
          </div>
        </div>
      </div>
    </CardWrapper >
  );
};

export default TransactionSimulation;