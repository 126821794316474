import React from 'react';

import CardWrapper from './common/CardWrapper';
import CardText from './common/CardText';

const Achievements = ({ isLoaded, likes }) => {
  return (
    <CardWrapper>
      <div className="lg:flex lg:space-x-2">
        <div className="lg:w-1/2">
          <CardText
            title='Achievements system'
            text="Achievements are unique visuals and nfts, that are given to Patrol members for extraordinary performance in making Web3 safe and are visible to everyone with SafeSoul Achievements despite the role. This is a true reputational mark that never is bought or transferred and is forever linked to the proud holder."
            size='L'
            id="achievements"
            likes={likes}
            isLoaded={isLoaded}
          />
        </div>

        <div className="relative lg:w-1/2 p-11">
          <picture>
            <source srcSet="/images/roadmap/achievements.webp" type="image/webp" />
            <source srcSet="/images/roadmap/achievements.png" type="image/png" />
            <img src="/images/roadmap/achievements.png" alt="achievements" className="object-contain h-[200px] lg:h-[400px] mx-auto" />
          </picture>
        </div>
      </div>
    </CardWrapper>
  );
};

export default Achievements;