import React from 'react';
import classnames from 'classnames';

import Title from './Title';

import Like from './Like';

const CardText = ({ id, likes, isLoaded, dark = false, className = '', title, text, additional = null, locked, size = 'S' }) => {
  let textSize = '';

  switch (size) {
    case 'L': textSize = {
      title: 'text-4xl sm:text-5xl',
      text: 'text-xl sm:text-2xl',
    }; break;
    case 'M': textSize = {
      title: 'text-3xl sm:text-[40px]',
      text: 'text-lg sm:text-xl',
    }; break;
    case 'S': textSize = {
      title: 'text-2xl sm:text-4xl',
      text: 'sm:text-xl',
    }; break;
    default: textSize = 'text-4xl'; break;
  }

  return (
    <div className={classnames('flex flex-col h-full p-4 sm:p-8 text-current', className)}>
      <Title className="mb-2 sm:mb-3" locked={locked}>
        <h2 className={classnames(textSize.title, 'font-bold break-words')}>{title}</h2>
      </Title>
      <div className={classnames(textSize.text, "opacity-60 font-medium")}>
        {text}
      </div>

      {
        additional ?
          <div className='mt-4'>
            {additional}
          </div>
          : null
      }

      <div className={classnames('pt-4 sm:pt-5 mt-auto transition duration-500', !isLoaded && 'opacity-0')}>
        {id && <Like
          id={id}
          likes={likes.find(({ section_id }) => section_id === id)?.likes}
          dark={dark}
        />
        }
      </div>
    </div>
  );
};

export default CardText;